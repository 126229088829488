import {
    IArchivedStudent,
    IArchivedStudentDTO,
    IArchivedStudentSchoolYear,
    IStudent,
    IStudentDTO,
    IStudentGroupAddHistoryDTO,
    IStudentGroupHistory,
    IStudentGroupRemoveHistoryDTO,
    IStudentListItem,
    IStudentListItemDTO,
    IStudentSchoolData,
    IStudentSchoolYear,
    IStudentStatusFields,
} from 'apps/early-stage-office/src/app/core/models/student.interface';
import { IAgreement, IStudentAgreement, IStudentAgreementDTO } from 'apps/early-stage-office/src/app/core/models/agreement.interface';
import { IStudentDeclarationStatus } from '../models/declaration.interface';
import { ISchoolYear } from '../models/school-year.interface';
import { IGroupSimple } from '../models/group.interface';
import { isAgencySchool, isCustomSchool } from '../typeguards';
import { IAgency, IAgencySchool } from '../models/agency.interface';
import { ISchoolClass, ISchoolLevel, ISchoolType } from '../models/school-type.interface';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { DictionaryState } from '../store/dictionary/dictionary.state';
import { ILevelBase } from '../models/level.interface';
import { getYear } from 'date-fns';
import { SchoolYearSelectorState } from '../store/school-year-selector/school-year-selector.state';

export type TStudentRequiredData = {
    year: number;
    years: ISchoolYear[];
    groups: IGroupSimple[];
    levels: ILevelBase[];
    agencies: IAgency[];
    schoolTypes: ISchoolType[];
};

export function isStudentGroupAddHistory(history: IStudentGroupAddHistoryDTO | IStudentGroupRemoveHistoryDTO): history is IStudentGroupAddHistoryDTO {
    return !!(history as IStudentGroupAddHistoryDTO).lessonDateAddedAt;
}

@Injectable({
    providedIn: 'root',
})
export class StudentsParserService {
    public path: string = '';

    constructor(private store: Store) {}

    public parse(dto: IStudentDTO): IStudent {
        const student: IStudent = {
            ...dto,
            hasSignedOrTerminatedAgreement: false,
            selectedSchoolYear: null,
            currentSchoolYear: null,
            currentDraftSchoolYear: null,
            studentSchoolYears: [],
            dayBirth: dto.dayBirth ? dto.dayBirth.toString() : null,
            monthBirth: dto.monthBirth ? dto.monthBirth.toString() : null,
            yearBirth: dto.yearBirth ? dto.yearBirth.toString() : null,
            birthday: dto.birthday,

            studentDeclarationStatuses: this.parseDeclarationStatuses(dto),
            latestStudentDeclarationStatus: null,
            latestStudentDeclaration: dto.studentDeclarations ? dto.studentDeclarations[0] : null,
            studentAgreements: dto.studentAgreements ? dto.studentAgreements.map(a => this.parseStudentAgreement(a, dto)).sort((a, b) => b.id - a.id) : undefined,
            archivedStudentAgreements: dto.archivedStudentAgreements ? dto.archivedStudentAgreements.map(a => this.parseStudentAgreement(a, dto)).sort((a, b) => b.id - a.id) : undefined,
            historyGroups: null,
            latestGroupHistoryAddedAt: null,
            latestGroupHistoryRemovedAt: null,
        };

        /*if (student.studentAgreements && student.studentAgreements.length > 0) {
            student.latestStudentAgreement = student.studentAgreements[student.studentAgreements.length - 1];

            if (student.studentAgreements.filter(a => a.status !== 'canceled').length > 1) {
                student.latestStudentAgreement = student.studentAgreements
                    .filter(a => a.status !== 'canceled').reverse()[0];
            } else {
                student.latestStudentAgreement = student.studentAgreements[0];

            }

            const sent = student.studentAgreements.filter(a => a.sentAt);

            if (sent.length > 0) {
                student.latestSentStudentAgreement = sent[sent.length - 1];
            }

            const signed = student.studentAgreements.filter(a => a.signedAt);

            if (signed.length > 0) {
                student.latestSignedStudentAgreement = signed[signed.length - 1];
            }
        }*/

        if (student.dayBirth && student.dayBirth.length === 1) {
            student.dayBirth = '0' + student.dayBirth;
        }

        if (student.monthBirth && student.monthBirth.length === 1) {
            student.monthBirth = '0' + student.monthBirth;
        }

        if (student.studentDeclarationStatuses?.length > 0) {
            const decisionStatuses = student.studentDeclarationStatuses.filter(a => a.type === 'decision');
            student.latestStudentDeclarationStatus = decisionStatuses ? decisionStatuses[0] : null;
        }

        for (const y of dto.studentSchoolYears) {
            const studentSchoolYear: IStudentSchoolYear = {
                ...y,
                school: null,
                schoolYearId: y.schoolYear?.id,
                schoolYear: y.schoolYear,
                continuation: !!dto.studentSchoolYears.find(year => year.schoolYear?.id === y.schoolYear?.id - 1),
                _studentAgreements: student.archivedStudentAgreements?.filter(a => a.schoolYear?.id === y.schoolYear?.id),
                _studentDeclarations: student.studentDeclarations?.filter(a => a.schoolYear?.id === y.schoolYear?.id),
                _studentDeclarationStatuses: student.studentDeclarationStatuses?.filter(a => a.schoolYear?.id === y.schoolYear?.id),
                _hasSignedOrTerminatedAgreement: student.archivedStudentAgreements?.some(a => (a.status === 'signed' || a.status === 'terminated') && a.schoolYear?.id === y.schoolYear?.id),
                age: getYear(y.schoolYear?.dateFrom) - getYear(dto.birthday),
                _declarationComment: dto.studentDeclarationStatuses
                    ?.filter(d => d.schoolYear.id === y.schoolYear?.id)
                    .map(d => {
                        return d.surveyDataComment || d.comment || d.studentDeclarationStatuses.find(i => i.surveyDataComment)?.surveyDataComment;
                    })
                    .find(d => d),
            };

            if (y.agreementStatus) {
                studentSchoolYear.agreementStatus = y.agreementStatus;
            }

            if (y.declarationStatus) {
                studentSchoolYear.declarationStatus = y.declarationStatus;
            }

            if (studentSchoolYear._studentDeclarations) {
                studentSchoolYear._latestStudentDeclaration = studentSchoolYear._studentDeclarations[0];
            }

            if (studentSchoolYear._studentDeclarations) {
                const decisionStatuses = studentSchoolYear._studentDeclarationStatuses.filter(a => a.type === 'decision');
                studentSchoolYear._latestStudentDeclarationStatus = decisionStatuses ? decisionStatuses[0] : null;
            }

            if (isCustomSchool(y.school)) {
                studentSchoolYear.school = y.school;
            } else if (isAgencySchool(y.school)) {
                // todo
                studentSchoolYear.school = {
                    ...y.school,
                    cityName: y.school?.locality?.name,
                    typeShortName: y.school.schoolType?.shortName,
                    hierarchyNumber: y.school?.schoolType?.hierarchyNumber,
                    listHierarchyNumber: y.school?.schoolType?.listHierarchyNumber,
                };
            }

            student.studentSchoolYears.push(studentSchoolYear);
        }

        const currentSchoolYear = this.store.selectSnapshot(DictionaryState.currentSchoolYear);
        const currentDraftSchoolYear = this.store.selectSnapshot(DictionaryState.currentDraftSchoolYear);
        const selectedSchoolYear = this.store.selectSnapshot(SchoolYearSelectorState.selectedSchoolYear);

        student.selectedSchoolYear = student.studentSchoolYears?.find(f => f.schoolYear?.id === selectedSchoolYear?.id);
        student.currentSchoolYear = student.studentSchoolYears?.find(f => f.schoolYear?.id === currentSchoolYear?.id);
        student.currentDraftSchoolYear = student.studentSchoolYears?.find(f => f.schoolYear?.id === currentDraftSchoolYear?.id);

        student.hasSignedOrTerminatedAgreement = student.studentAgreements?.some(a => a.agreementStatus === 'signed' || a.agreementStatus === 'terminated');

        if (dto.historyGroups) {
            const historyDTO = [...dto.historyGroups].reverse();
            let history: IStudentGroupHistory[] = [];

            let latestHistory: IStudentGroupHistory = null;
            for (const historyGroup of historyDTO) {
                if (isStudentGroupAddHistory(historyGroup)) {
                    latestHistory = {
                        ...historyGroup,
                        removedAt: null,
                        lessonDateRemovedAt: null,
                        lessonDays: historyGroup.lessonDays || 0,
                        current: true,
                    };
                    history.push(latestHistory);
                } else if (latestHistory && historyGroup) {
                    if (latestHistory?.group?.id === historyGroup.group?.id) {
                        latestHistory.removedAt = historyGroup.removedAt;
                        latestHistory.lessonDays = historyGroup.lessonDays;
                        latestHistory.lessonDateRemovedAt = historyGroup.lessonDateRemovedAt;
                        latestHistory.current = false;
                    }
                }
            }

            history = history.filter(h => h.lessonDateRemovedAt || h.current);
            history.reverse();

            student.historyGroups = history;

            student.latestGroupHistoryAddedAt = history.find(h => h.lessonDateAddedAt)?.lessonDateAddedAt;
            student.latestGroupHistoryRemovedAt = history.find(h => h.lessonDateRemovedAt)?.lessonDateRemovedAt;

            if (!student.nextGroupFirstLessonDate) {
                student.nextGroupFirstLessonDate = student.latestGroupHistoryAddedAt;
            }
        }

        return student;
    }

    public parseArchived(dto: IArchivedStudentDTO, schoolYear: number): IArchivedStudent {
        return {
            ...dto,
            selectedSchoolYear: dto.studentSchoolYears.find(a => a.schoolYear?.id === schoolYear) as IArchivedStudentSchoolYear,
        };
    }

    public parseListItem(dto: IStudentListItemDTO, data: TStudentRequiredData): IStudentListItem {
        const item: IStudentListItem = {
            ...dto,
            _advance: {
                advanceAmount: dto._advance.advanceAmount || 0,
                advanceStatus: dto._advance.advanceStatus || 'no-advance',
                advancePaymentUrL: dto._advance.advancePaymentUrL || '',
            },
            selectedSchoolYear: undefined,
            nextSchoolYear: undefined,
            previousSchoolYear: undefined,
            studentSchoolYears: [],
            agency: data.agencies?.find(a => a.id === dto.agencyId),
            primaryEmail: dto.contacts && dto.contacts.length > 0 ? dto.contacts.find(c => c.isPrimary)?.email : null,
            primaryPhone: dto.contacts && dto.contacts.length > 0 ? dto.contacts.find(c => c.isPrimary)?.phone : null,
            otherEmails: dto.contacts
                ?.filter(c => !c.isPrimary)
                .filter(c => c.email)
                .map(c => c.email),
            hasSignedOrTerminatedAgreement: false,
            isSelectedYearArchived: true,
        };

        const classes = [...(data.schoolTypes || [])]
            .reduce<ISchoolLevel[]>((a, b) => {
                a.push(...b.schoolLevels);
                return a;
            }, [])
            .reduce<ISchoolClass[]>((a, b) => {
                a.push(...b.classes);
                return a;
            }, []);

        const schools = data.agencies
            ? [...data.agencies].reduce<IAgencySchool[]>((a, b) => {
                  a.push(...b.schools);

                  return a;
              }, [])
            : [];

        for (const y of dto.studentSchoolYears) {
            const studentSchoolYear: IStudentSchoolYear = {
                schoolYearId: y.schoolYearId,
                schoolYear: data.years.find(c => c.id === y.schoolYearId),
                group: y.groupId ? data.groups?.find(c => c.id === y.groupId) || ({} as IGroupSimple) : null,
                level: y.levelId ? data.levels?.find(c => c.id === y.levelId) || ({} as ILevelBase) : null,
                agreementStatus: y.agreementStatus,
                declarationStatus: y.declarationStatus,
                firstUnit: y.firstUnit,
                secondUnit: y.secondUnit,
                secondLevel: data.levels?.find(c => c.id === y.secondLevelId),
                continuation: !!dto.studentSchoolYears.find(year => year.schoolYearId === y.schoolYearId - 1),
                school: null,
                className: y.className,
                classNameType: y.classNameType,
                classNumber: y.classNumberId ? classes.find(c => c.id === y.classNumberId) || ({} as ISchoolClass) : null,
                enrolledAt: y.enrolledAt,
                age: getYear(data.years.find(c => c.id === y.schoolYearId)?.dateFrom) - getYear(dto.birthday),
                statusFields: y.statusFields || ({} as IStudentStatusFields),
                hasSiblings: y.hasSiblings,
                _hasSignedOrTerminatedAgreement: y.agreementStatus === 'terminated' || y.agreementStatus === 'signed',
                _declarationComment: null,
                isMixedResponse: y.isMixedResponse,
            };

            if (y.school) {
                if (isCustomSchool(y.school)) {
                    if (y.school?.schoolTypeId) {
                        const schoolTypeId = y.school.schoolTypeId;
                        studentSchoolYear.school = {
                            ...y.school,
                            schoolType: data.schoolTypes?.find(s => s.id === schoolTypeId),
                            typeShortName: data.schoolTypes?.find(s => s.id === schoolTypeId)?.shortName,
                            hierarchyNumber: data.schoolTypes?.find(s => s.id === schoolTypeId)?.hierarchyNumber,
                            listHierarchyNumber: data.schoolTypes?.find(s => s.id === schoolTypeId)?.listHierarchyNumber,
                        };
                    } else {
                        studentSchoolYear.school = {} as IStudentSchoolData;
                    }
                } else {
                    // todo
                    const schoolId = y.school.id;

                    if (schools?.some(a => a.id === schoolId)) {
                        studentSchoolYear.school = schools
                            ?.filter(a => a.id === schoolId)
                            .map(a => {
                                return {
                                    id: a.id,
                                    name: a.name,
                                    address: a.address,
                                    number: a.number,
                                    cityName: a.locality?.name,
                                    typeShortName: a.schoolType?.shortName,
                                    schoolType: a.schoolType,
                                    hierarchyNumber: data.schoolTypes?.find(s => s.id === a.schoolType?.id)?.hierarchyNumber,
                                    listHierarchyNumber: data.schoolTypes?.find(s => s.id === a.schoolType?.id)?.listHierarchyNumber,
                                };
                            })[0];
                    } else {
                        studentSchoolYear.school = {} as IStudentSchoolData;
                    }
                }
            }

            item.studentSchoolYears.push(studentSchoolYear);
        }

        item.selectedSchoolYear = item.studentSchoolYears.find(y => y.schoolYear?.id === data.year);
        item.isSelectedYearArchived = !(item.selectedSchoolYear.schoolYear.isCurrent || item.selectedSchoolYear.schoolYear.isCurrentDraft);
        item.nextSchoolYear = item.studentSchoolYears.find(y => y.schoolYear?.id === data.year + 1);
        item.previousSchoolYear = item.studentSchoolYears.find(y => y.schoolYear?.id === data.year - 1);

        item.hasSignedOrTerminatedAgreement = item.studentSchoolYears.some(a => a.agreementStatus === 'signed' || a.agreementStatus === 'terminated');
        return item;
    }

    private parseDeclarationStatuses(dto: IStudentDTO): IStudentDeclarationStatus[] {
        const statuses: IStudentDeclarationStatus[] = [];

        if (!dto.studentDeclarations) {
            return dto.studentDeclarationStatuses;
        }

        for (const status of dto.studentDeclarationStatuses) {
            statuses.push({
                ...status,
                studentDeclaration: status.studentDeclarationId ? dto.studentDeclarations.find(d => d.id === status.studentDeclarationId) : undefined,
            });
        }

        return statuses;
    }

    private parseStudentAgreement(a: IStudentAgreementDTO, studentDTO: IStudentDTO): IStudentAgreement {
        const agreement: IStudentAgreement = {
            id: a.id,
            name: a.name,
            city: a.city,
            status: a.status,
            sentStatus: a.sentStatus,
            agreementStatus: a.agreementStatus,
            version: a.version,
            validate: a.validate,
            email: a.email,
            valid: a.validate.sameAgency && a.validate.sameRealization && !a.validate.manuallyInvalidated && (a.validPesel || !a.fieldsDecoded?.pesel),
            manuallyValidated: a.manuallyValidated,
            validPesel: a.validPesel,
            parent: a.parent,
            group: a.group,
            agency: a.agency,
            agreement: a.agreement,
            agreementStudentFiles: a.agreementStudentFiles,
            sentAgreements: a.sentAgreements,
            nr: a.nr,
            customNr: a.customNr,
            isCustom: a.isCustom,
            filename: a.filename,

            sentAt: a.sentAt,
            signedAt: a.signedAt,
            schoolYear: a.schoolYear,
            realization: a.realization,
            comment: a.comment,
            student: {
                id: studentDTO.id,
                lastName: studentDTO.lastName,
                firstName: studentDTO.firstName,
            },

            fieldsDecoded: Array.isArray(a.fieldsDecoded) || Object.keys(a.fieldsDecoded).length === 0 ? undefined : a.fieldsDecoded,
        };

        if (studentDTO.anonymizedAt) {
            agreement.student.anonymizedAt = studentDTO.anonymizedAt;
        }

        if (agreement.agreement && (agreement.agreement as IAgreement).agreementFiles) {
            delete (agreement.agreement as IAgreement).agreementFiles;
        }

        if (a._city) {
            agreement._city = a._city;
        }

        if (a.agreementStudentFiles) {
            a.agreementStudentFiles = a.agreementStudentFiles.sort((f1, f2) => {
                return f1.fileTypeHierarchyNumber - f2.fileTypeHierarchyNumber;
            });
        }

        if (a.canceledAt) {
            agreement.canceledAt = a.canceledAt;
        }

        if (a.terminatedAt) {
            const type = {
                'terminated-by-parent': 'Rezygnacja ze strony rodzica',
                'terminated-by-school': 'Rezygnacja ze strony szkoły',
                'terminated-by-both': 'Za porozumieniem stron',
                'agreement-duplicate': 'Zastąpiono inną wersją umowy / duplikat',
                'terminated-by-overlay': 'Rozwiązano w nakładce',
            };

            agreement.terminatedAt = a.terminatedAt;
            agreement.terminatedCreatedAt = a.terminatedCreatedAt;
            agreement.terminatedUpdatedAt = a.terminatedUpdatedAt;

            agreement.terminatedAdditionalComment = a.terminatedAdditionalComment;
            agreement.terminatedBy = a.terminatedBy;
            agreement.terminatedReasons = a.terminatedReasons;
            agreement.generalTerminatedReason = a.generalTerminatedReason;
            agreement.terminatedType = type[a.terminatedType];
        }

        return agreement;
    }
}
